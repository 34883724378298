import { useMutation, useQuery } from '@apollo/client';
import { GET_ACTIVITY_DATA_WITH_GOALS } from 'api/fitbit/fitbit.query';
import { CREATE_GOAL_HISTORY, DELETE_GOAL_HISTORY } from 'api/goal/goal.mutation';
import { GET_ALL_CARE_PLANS } from 'api/progress/allCarePlans.query';
import { GET_CARE_PLAN } from 'api/progress/carePlan.query';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import round from 'lodash/round';
import { filterGoalsCurrentWeek } from 'pages/home/hooks/useCarePlan';
import { useEffect, useMemo } from 'react';
import { DATE_FORMAT, DEFAULT_POLLING_INTERVAL, parseHealthieDate } from 'utils/helpers';
import { Mixpanel } from 'utils/mixpanel';

export type CompleteActivityGoalType = {
  stepsGoal?: number;
  percentage?: number;
  currentSteps: number;
  goalLoading: boolean;
  goalName: string;
  heartRateGoal?: number | null;
  isFirstWeek: boolean;
  isLoading: boolean;
};

function convertDateFormat(originalDate: string): string {
  const [month, day, year] = originalDate.split('.');
  const convertedDate = `${year}-${month}-${day}`;
  return convertedDate;
}

export const useCompleteActivityGoal = (): CompleteActivityGoalType => {
  const today = format(Date.now(), DATE_FORMAT);
  const [createGoalHistory] = useMutation(CREATE_GOAL_HISTORY);
  const [deleteGoalHistory] = useMutation(DELETE_GOAL_HISTORY);

  const { data, loading: goalLoading } = useQuery(GET_ACTIVITY_DATA_WITH_GOALS, {
    variables: { date: today, start_range: today, end_range: today },
    fetchPolicy: 'network-only',
    pollInterval: DEFAULT_POLLING_INTERVAL,
  });

  const { data: carePlanData, loading: carePlanLoading } = useQuery(GET_ALL_CARE_PLANS, {
    skip: !data?.currentUser?.id,
    variables: {
      patient_id: data?.currentUser?.id || '',
    },
  });

  const courseStartDate = useMemo(() => {
    let motivCareplanDate = '';
    const week1 = carePlanData?.carePlans
      ?.find((item) => /^Week 1\b/.test(item?.name || ''))
      ?.description?.slice(8, 18);
    const motivCareplan = carePlanData?.carePlans?.find((item) => item.name?.includes('Careplan'))?.created_at;
    if (motivCareplan) {
      motivCareplanDate = format(new Date(parseHealthieDate(motivCareplan) ?? ''), 'MM.dd.yyyy');
    }
    return week1 ? week1 : motivCareplanDate ? motivCareplanDate : '';
  }, [carePlanData]);

  const goals = data?.currentUser?.active_care_plan?.goals;
  const todayEntries = data?.entries;

  const isFirstWeek = useMemo(() => {
    if (courseStartDate) {
      return !(new Date(convertDateFormat(courseStartDate)) < new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000));
    }
    return false;
  }, [courseStartDate]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filteredGoals = goals?.filter((goal) => /moving/i.test(goal?.name?.toLowerCase() || '')) || ([] as any);

  const newFilteredGoals = filterGoalsCurrentWeek(filteredGoals);

  const goal = !isEmpty(newFilteredGoals) ? newFilteredGoals[0] : null;

  const isGoalCompleted = goal?.is_completed_for_date;

  const heartRateGoal =
    goal?.subgoals && !isEmpty(goal?.subgoals) ? parseInt(goal?.subgoals[0]?.name || '') : undefined;

  const stepsGoal = goal?.description ? parseInt(goal?.description || '') : undefined;

  const currentSteps = todayEntries
    ? todayEntries.reduce((accumulator, currentEntry) => {
        if (currentEntry?.metric_stat) {
          return accumulator + currentEntry.metric_stat;
        }
        return accumulator;
      }, 0)
    : 0;

  const percentage = stepsGoal ? (stepsGoal > currentSteps ? round((currentSteps / stepsGoal) * 100) : 100) : undefined;

  const shouldComplete = !isNil(goal) && percentage && isGoalCompleted === false && percentage === 100;
  const shouldIncomplete =
    (!isNil(goal) && !isNil(percentage) && isGoalCompleted === true && percentage < 100) || !percentage;

  useEffect(() => {
    if (shouldComplete) {
      Mixpanel.track('Keep_Moving_Goal');
      createGoalHistory({
        variables: {
          user_id: data?.currentUser?.id,
          goal_id: goal?.id,
          completed_on: format(Date.now(), DATE_FORMAT),
        },
        refetchQueries: [GET_CARE_PLAN],
      });
    }
  }, [shouldComplete, createGoalHistory, goal, data?.currentUser?.id]);

  useEffect(() => {
    if (shouldIncomplete) {
      deleteGoalHistory({
        variables: {
          id: goal?.id,
          completed_on: format(Date.now(), DATE_FORMAT),
        },
        refetchQueries: [GET_CARE_PLAN],
      });
    }
  }, [shouldIncomplete, deleteGoalHistory, goal]);

  return {
    stepsGoal,
    percentage,
    currentSteps,
    goalLoading,
    goalName: goal?.name || '',
    heartRateGoal,
    isFirstWeek,
    isLoading: goalLoading || carePlanLoading,
  };
};
