import { useQuery } from '@apollo/client';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Entry, Goal } from '__generated__/graphql';
import { GET_BLOOD_PRESSURE_MEASUREMENTS } from 'api/bloodPressureCuff/bloodPressureCuff.query';
import { GET_ACTIVITY_DATA } from 'api/fitbit/fitbit.query';
import { GET_GOALS } from 'api/goal/goal.query';
import { GET_CURRENT_USER_PROVIDERS } from 'api/user/user.query';
import { countBy, entries, flow, head, last, maxBy, meanBy, partialRight } from 'lodash';
import floor from 'lodash/floor';
import isEmpty from 'lodash/isEmpty';
import mean from 'lodash/mean';
import { getGoalType } from 'pages/home/components/Card/TaskCard';
import { getAverage, getDates, getStepsByDate } from 'pages/progress/activity/timeUtils';
import { useGetMeasurementsStatistics } from 'pages/progress/bloodPressure/components/hooks';
import { groupMeasurements, useGetAverage } from 'pages/progress/bloodPressure/utils';
import { useGetMappedMedications } from 'pages/progress/medications/hooks';
import { filterGoalsByDateRange, sortByDateDesc } from 'pages/progress/medications/utils';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Maybe } from 'utils/types';

import { Button } from 'components/ui/Button/Button';

import { DateOption } from '../types';
import { WeeklyReportPdf } from './WeeklyReportPdf/WeeklyReportPdf';

type Props = {
  data: {
    goals: Goal[];
    startDate: string;
    endDate: string;
  };
  dateRange: Maybe<DateOption>;
};

export const DownloadButton: React.FC<Props> = ({ data, dateRange }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.weeklyReport' });

  const { data: userProviders } = useQuery(GET_CURRENT_USER_PROVIDERS);
  const phoneNumber = userProviders?.currentUser?.providers?.find(
    (provider) => provider?.qualifications?.toLowerCase() === 'coach',
  )?.phone_number;

  const { startDate, endDate, goals } = data;

  // Blood Pressure data
  const { data: bpData, loading: bpLoading } = useQuery(GET_BLOOD_PRESSURE_MEASUREMENTS, {
    variables: {
      start_range: startDate,
      end_range: endDate,
    },
  });

  const bpEntries = (bpData?.entries || []) as Entry[];
  const { systolicData, diastolicData, heartRateData } = groupMeasurements(bpEntries, startDate, endDate);
  const { requiredMeasurementsInWeek, goal: bpGoal } = useGetMeasurementsStatistics(goals);
  const allReadings = diastolicData.filter((item) => item.y !== 0).length;

  const averageBloodPressure = useGetAverage(systolicData, diastolicData);
  const averageHeartRate = floor(mean(heartRateData), 0);

  // Activity data
  const { data: activityData, loading: activityLoading } = useQuery(GET_ACTIVITY_DATA, {
    fetchPolicy: 'cache-and-network',
    variables: {
      start_range: startDate,
      end_range: endDate,
    },
  });

  const activityEntries = activityData?.entries || [];
  const hasEntries = !isEmpty(activityEntries);

  const chartData = hasEntries ? getStepsByDate(activityEntries, getDates(startDate, endDate, 'yyyy-MM-dd')) : [];

  const average = `${getAverage(chartData)}`;

  const goal = useMemo(() => {
    return goals?.find((item) => getGoalType(item) === 'moving');
  }, [goals]);

  const stepsGoal = goal?.description ? parseInt(goal?.description || '') : 0;

  // Medications data
  const { data: medicationsData, loading: medicationsLoading } = useQuery(GET_GOALS, {
    variables: {
      keywords: 'medications',
      start_range: startDate,
      end_range: endDate,
    },
  });

  const filteredMedicationsAnswers = useGetMappedMedications(medicationsData);
  const sortedAnswers = useMemo(() => sortByDateDesc(filteredMedicationsAnswers), [filteredMedicationsAnswers]);

  const filteredAnswers = useMemo(() => {
    return filterGoalsByDateRange(sortedAnswers, { startRange: startDate, endRange: endDate });
  }, [sortedAnswers, startDate, endDate]);

  const avgPercentage = !isEmpty(filteredAnswers) ? meanBy(filteredAnswers, 'percentageCompleted') : 0;

  const mostOccurringAnswer: string = flow(
    partialRight(countBy, 'name'),
    entries,
    partialRight(maxBy, last),
    head,
  )(filteredAnswers);

  return (
    <PDFDownloadLink
      document={
        <WeeklyReportPdf
          phoneNumber={phoneNumber}
          dateRange={dateRange}
          bloodPressure={{
            completedMeasurements: allReadings,
            requiredMeasurementsInWeek,
            averageBloodPressure,
            averageHeartRate,
            goal: bpGoal,
          }}
          activity={{
            average,
            stepsGoal,
          }}
          medications={{
            avgPercentage,
            mostOccurringAnswer,
          }}
        />
      }
      fileName="Weekly Report.pdf"
    >
      {() => (
        <div style={{ width: 100 }}>
          <Button
            size="sm"
            style={{ visibility: bpLoading || activityLoading || medicationsLoading ? 'hidden' : 'visible' }}
          >
            {t('download')}
          </Button>
        </div>
      )}
    </PDFDownloadLink>
  );
};
