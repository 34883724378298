import { useQuery } from '@apollo/client';
import { GET_SINGLE_APPOINTMENT } from 'api/appointment/appointment.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { useAppointmentDate } from 'pages/home/components/Card/hooks';
import { useNotificationPreferences } from 'pages/settings/components/Notifications/hooks/useNotificationPreferences';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { capitalizeWords } from 'utils/helpers';

import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import { TaskCompletionPage } from 'components/ui/TaskCompletionPage/TaskCompletionPage';

import { FooterButtonStyled } from './ScheduleActions.styles';

export const ActionCompleted: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.scheduleAppointment' });
  const { appointmentId, actionType } = useParams();
  const navigate = useNavigate();

  const { data: userData, loading: isUserLoading } = useQuery(GET_CURRENT_USER_DATA);

  const { hasIntakeHistory } = useNotificationPreferences();

  const { data: appointmentData, loading: isAppointmentLoading } = useQuery(GET_SINGLE_APPOINTMENT, {
    variables: {
      id: appointmentId,
    },
    fetchPolicy: 'no-cache',
  });

  const date = useAppointmentDate(appointmentData?.appointment?.start);

  const getProps = () => {
    const userName = capitalizeWords(userData?.currentUser?.first_name);
    switch (actionType) {
      case 'canceled-completed':
        return {
          taskTitle: t('canceled.title'),
          headerTitle: t('canceled.header'),
          taskDescription: t('canceled.information'),
        };
      case 'schedule-completed':
        return {
          taskTitle: t('completed.title', { value: userName }),
          headerTitle: t('completed.header'),
          taskDescription: t('completed.information', { value: date }),
        };
      case 'reschedule-completed':
        return {
          taskTitle: t('completed.title', { value: userName }),
          headerTitle: t('rescheduled.header'),
          taskDescription: t('rescheduled.information', { value: date }),
        };
      default:
        return {
          taskTitle: '',
          headerTitle: '',
          taskDescription: '',
        };
    }
  };

  if (!isAppointmentLoading && appointmentData?.appointment === null) {
    return <Navigate to={paths.homePage} />;
  }

  if (isUserLoading || isAppointmentLoading) {
    return <LoadingPage />;
  }
  return (
    <TaskCompletionPage
      {...getProps()}
      color="other.backgroundBlue"
      footer={
        <FooterButtonStyled
          onClick={() =>
            actionType === 'schedule-completed' && !hasIntakeHistory
              ? navigate(paths.notificationPreferences)
              : navigate(paths.homePage)
          }
          variant="secondary"
        >
          {actionType === 'schedule-completed' && !hasIntakeHistory ? t('next') : t('completed.goHome')}
        </FooterButtonStyled>
      }
    />
  );
};
