import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';
import { Mixpanel } from 'utils/mixpanel';

import LeftLabelCheckbox from 'components/form/Checkbox/LeftLabelCheckbox/LeftLabelCheckbox';
import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';

import { Container, NotificationContainer } from './Notifications.styles';
import { ApptReminderPreferences, EngagementReminderDays } from './constants';
import { useNotificationPreferences } from './hooks/useNotificationPreferences';

export const Notification: React.FC = () => {
  const navigate = useNavigate();
  const {
    parsedJSON,
    userDataLoading,
    updateLoading,
    currentUpdating,
    handleApptReminderChange,
    handleEngagementDayChange,
    getReminderPreference,
  } = useNotificationPreferences();

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Settings - Notifications' });
  }, []);

  return (
    <BaseLayout
      headerProps={{ title: 'Notifications', variant: 'back', onBackClick: () => navigate(paths.settings.main) }}
      footer={null}
    >
      {ApptReminderPreferences.map((preference) => (
        <div key={preference.type}>
          <Container>{preference.heading}</Container>
          {preference.options.map((option) => (
            <NotificationContainer key={option.type}>
              <LeftLabelCheckbox
                labelProps={{
                  style: {
                    color: '#4D4D4D',
                    paddingLeft: '1.25rem',
                  },
                }}
                loading={userDataLoading || (updateLoading && currentUpdating === preference.type + option.type)}
                label={option.display}
                checked={getReminderPreference(preference.type, option.type)}
                onChange={() => handleApptReminderChange(preference.type, option.type)}
              />
            </NotificationContainer>
          ))}
        </div>
      ))}
      <Container>Motiv engagement TEXT reminders sent on:</Container>
      {EngagementReminderDays.map((day) => (
        <NotificationContainer key={day.name}>
          <LeftLabelCheckbox
            labelProps={{
              style: {
                paddingLeft: '1.25rem',
              },
            }}
            loading={updateLoading && currentUpdating === day.name}
            label={`${day.name.charAt(0).toUpperCase()}${day.name.slice(1)}`}
            checked={parsedJSON?.reminderDays?.includes(day.name)}
            onChange={() => handleEngagementDayChange(day.name)}
          />
        </NotificationContainer>
      ))}
    </BaseLayout>
  );
};

export default Notification;
