import { useQuery } from '@apollo/client';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Entry } from '__generated__/graphql';
import { GET_BLOOD_PRESSURE_MEASUREMENTS } from 'api/bloodPressureCuff/bloodPressureCuff.query';
import { GET_CURRENT_USER_DATA, GET_CURRENT_USER_PROVIDERS } from 'api/user/user.query';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { TimeSwitchContainer } from 'pages/progress/activity/Activity.styles';
import {
  ButtonContainer,
  NoDataContainer,
  ReadingsContainer,
} from 'pages/progress/activity/readings/ActivityReadings.styles';
import { getCurrentTimeInterval, getIntervalForTab, getTimeRange } from 'pages/progress/activity/timeUtils';
import { mapIndexToTabName, mapTabNameToIndex } from 'pages/progress/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { parseHealthieDate } from 'utils/helpers';
import { Mixpanel } from 'utils/mixpanel';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';
import { Spinner } from 'components/ui/Spinner/Spinner';
import TimeTabs from 'components/ui/Tabs/TimeTabs';
import { TimeTabsIndex } from 'components/ui/Tabs/TimeTabs';
import { TimeSwitch, TimeSwitchType } from 'components/ui/TimeSwitch/TimeSwitch';
import { Typography } from 'components/ui/Typography/Typography';

import BloodPressureReadingsList from '../components/BloodPressureReadingsList';
import { BloodPressureReadingPdf } from './BloodPressureReadingPdf';

export const BloodPressureReading: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.bloodPressure.readings' });

  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get('tab');

  const [currentTab, setCurrentTab] = useState(0);
  const [currentSubTab, setCurrentSubTab] = useState<TimeSwitchType>('this');
  const [timeInterval, setTimeInterval] = useState(getTimeRange('thisWeek'));

  const navigate = useNavigate();

  useEffect(() => {
    const tabIndex = mapTabNameToIndex(tabParam);
    setCurrentTab(tabIndex);
  }, [tabParam]);

  const { data, loading: activityLoading } = useQuery(GET_BLOOD_PRESSURE_MEASUREMENTS, {
    variables: {
      start_range: timeInterval.startRange,
      end_range: timeInterval.endRange,
    },
  });

  const { data: currentUserData } = useQuery(GET_CURRENT_USER_DATA);

  const updateData = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const disgustingHack = (entry: Entry) => {
      const ids = [
        '2636381',
        '3649498',
        '2684076',
        '2816797',
        '3764361',
        '2744183',
        '3647788',
        '2748500',
        '3757298',
        '3972696',
        '2798813',
        '4063762',
        '3567255',
        '3567182',
        '3649182',
        '3733802',
        '3571707',
        '3882035',
        '2806713',
        '4030427',
        '4085616',
        '4064109',
        '4043352',
      ];

      const datesToCheck = ['2024-03-23', '2024-03-24', '2024-03-25', '2024-03-26', '2024-03-27'];

      const created_date = format(parseHealthieDate(entry.created_at), 'yyyy-MM-dd');
      const updated_date = format(parseHealthieDate(entry.updated_at), 'yyyy-MM-dd');

      // check if created_at in list of dates
      if (
        ids.includes(currentUserData?.currentUser?.id || '') &&
        entry.created_at &&
        datesToCheck.includes(created_date) &&
        updated_date === '2024-03-28'
      ) {
        return {
          ...entry,
          updated_at: entry.created_at,
        } as Entry;
      }
      return entry;
    };

    return data?.entries?.map((entry) => disgustingHack(entry as Entry)) || [];
  }, [currentUserData?.currentUser?.id, data?.entries]);

  const { data: userProviders } = useQuery(GET_CURRENT_USER_PROVIDERS);
  const phoneNumber = userProviders?.currentUser?.providers?.find(
    (provider) => provider?.qualifications?.toLowerCase() === 'coach',
  )?.phone_number;

  const onTabChange = (tab: number) => {
    setCurrentTab(tab);
    setCurrentSubTab('this');

    const tabName = mapIndexToTabName(tab);
    navigate(`${paths.bloodPressureReadings}?tab=${tabName}`, { replace: true });
  };

  useEffect(() => {
    setTimeInterval(getIntervalForTab(currentTab, currentSubTab));
  }, [currentTab, currentSubTab]);

  const currentTimeInterval = getCurrentTimeInterval(currentTab, currentSubTab);

  return (
    <BaseLayout headerProps={{ title: t('title'), variant: 'back', onBackClick: () => navigate(-1) }} footer={null}>
      <TimeTabs selectedTab={currentTab} onTabChange={onTabChange} />
      {currentTab !== TimeTabsIndex.all_time && (
        <TimeSwitchContainer>
          <TimeSwitch
            time={currentTab === TimeTabsIndex.week ? 'week' : 'month'}
            onSelect={(value: TimeSwitchType) => setCurrentSubTab(value)}
            selected={currentSubTab}
          />
        </TimeSwitchContainer>
      )}

      {data?.entries && (
        <ButtonContainer>
          <PDFDownloadLink
            document={
              <BloodPressureReadingPdf
                data={updateData?.filter((x): x is Entry => !isNil(x))}
                coachPhone={phoneNumber}
              />
            }
            fileName="Blood pressure readings.pdf"
          >
            {() => (
              <Button
                size="sm"
                style={{ visibility: activityLoading ? 'hidden' : 'visible' }}
                onClick={() => {
                  Mixpanel.track('Progress_all_readings_downloads', {
                    category: 'Blood_pressure',
                    interval: currentTimeInterval,
                  });
                }}
              >
                {t('download')}
              </Button>
            )}
          </PDFDownloadLink>
        </ButtonContainer>
      )}
      <ReadingsContainer>
        {activityLoading && (
          <NoDataContainer>
            <Spinner />
          </NoDataContainer>
        )}
        {isEmpty(updateData) && !activityLoading && (
          <NoDataContainer>
            <Typography variant="Body1Bold">{t('noDataTitle')}</Typography>
            <Typography variant="Helper1">{t('noDataSubtitle')}</Typography>
          </NoDataContainer>
        )}
        {!activityLoading && data?.entries && (
          <BloodPressureReadingsList entries={updateData.filter((x): x is Entry => !isNil(x))} />
        )}
      </ReadingsContainer>
    </BaseLayout>
  );
};

export default BloodPressureReading;
