import { useQuery } from '@apollo/client';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { GET_ACTIVITY_DATA } from 'api/fitbit/fitbit.query';
import { GET_CURRENT_USER_PROVIDERS } from 'api/user/user.query';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { mapIndexToTabName, mapTabNameToIndex } from 'pages/progress/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { Mixpanel } from 'utils/mixpanel';

import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';
import { Spinner } from 'components/ui/Spinner/Spinner';
import TimeTabs from 'components/ui/Tabs/TimeTabs';
import { TimeTabsIndex } from 'components/ui/Tabs/TimeTabs';
import { TimeSwitch, TimeSwitchType } from 'components/ui/TimeSwitch/TimeSwitch';
import { Typography } from 'components/ui/Typography/Typography';

import { TimeSwitchContainer } from '../Activity.styles';
import ActivityReadingsList from '../components/ActivityReadingsList';
import { getCurrentTimeInterval, getIntervalForTab, getTimeRange } from '../timeUtils';
import { ButtonContainer, NoDataContainer, ReadingsContainer } from './ActivityReadings.styles';
import { ActivityReadingsPdf } from './ActivityReadingsPdf';

export const ActivityReadings: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.activity.readings' });

  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get('tab');

  const [currentTab, setCurrentTab] = useState(0);
  const [currentSubTab, setCurrentSubTab] = useState<TimeSwitchType>('this');
  const [timeInterval, setTimeInterval] = useState(getTimeRange('thisWeek'));

  const navigate = useNavigate();

  const { data, loading: activityLoading } = useQuery(GET_ACTIVITY_DATA, {
    variables: {
      start_range: timeInterval.startRange,
      end_range: timeInterval.endRange,
    },
  });

  const { data: userProviders } = useQuery(GET_CURRENT_USER_PROVIDERS);
  const phoneNumber = userProviders?.currentUser?.providers?.find(
    (provider) => provider?.qualifications?.toLowerCase() === 'coach',
  )?.phone_number;

  const entriesWithValues = data?.entries?.filter(
    (entry) => !isNil(entry) && entry?.metric_stat && entry.metric_stat > 0,
  );

  useEffect(() => {
    const tabIndex = mapTabNameToIndex(tabParam);
    setCurrentTab(tabIndex);
  }, [tabParam]);

  const onTabChange = (tab: number) => {
    setCurrentTab(tab);
    setCurrentSubTab('this');

    const tabName = mapIndexToTabName(tab);
    navigate(`${paths.activityReadings}?tab=${tabName}`, { replace: true });
  };

  const currentTimeInterval = getCurrentTimeInterval(currentTab, currentSubTab);

  useEffect(() => {
    setTimeInterval(getIntervalForTab(currentTab, currentSubTab));
  }, [currentTab, currentSubTab]);
  return (
    <BaseLayout headerProps={{ title: t('title'), variant: 'back', onBackClick: () => navigate(-1) }} footer={null}>
      <TimeTabs selectedTab={currentTab} onTabChange={onTabChange} />
      {currentTab !== TimeTabsIndex.all_time && (
        <TimeSwitchContainer>
          <TimeSwitch
            time={currentTab === TimeTabsIndex.week ? 'week' : 'month'}
            onSelect={(value: TimeSwitchType) => setCurrentSubTab(value)}
            selected={currentSubTab}
          />
        </TimeSwitchContainer>
      )}
      {data?.entries && (
        <ButtonContainer>
          <PDFDownloadLink
            document={<ActivityReadingsPdf data={data.entries || []} coachPhone={phoneNumber} />}
            fileName="Activity Readings.pdf"
          >
            {() => (
              <Button
                size="sm"
                style={{ visibility: activityLoading ? 'hidden' : 'visible' }}
                onClick={() => {
                  Mixpanel.track('Progress_all_readings_downloads', {
                    category: 'Activity',
                    interval: currentTimeInterval,
                  });
                }}
              >
                {t('download')}
              </Button>
            )}
          </PDFDownloadLink>
        </ButtonContainer>
      )}

      <ReadingsContainer>
        {activityLoading && (
          <NoDataContainer>
            <Spinner />
          </NoDataContainer>
        )}
        {isEmpty(entriesWithValues) && !activityLoading && (
          <NoDataContainer>
            <Typography variant="Body1Bold">{t('noDataTitle')}</Typography>
            <Typography variant="Helper1">{t('noDataSubtitle')}</Typography>
          </NoDataContainer>
        )}
        {!activityLoading && !isEmpty(entriesWithValues) && <ActivityReadingsList entries={entriesWithValues || []} />}
      </ReadingsContainer>
    </BaseLayout>
  );
};

export default ActivityReadings;
