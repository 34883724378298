import HealthwiseApiService from 'api/healthwise/healthwiseApiService';
import { HealthwiseArticleApiResponse } from 'api/healthwise/types';
import { ReactComponent as ChevronDown } from 'assets/vectors/fi-rr-angle-small-down.svg';
import { ReactComponent as ChevronUp } from 'assets/vectors/fi-rr-angle-small-up.svg';
import { useCarePlanGoals } from 'pages/home/hooks/useCarePlan';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import { Header } from 'components/layout/BaseLayout/Header';
import { ArticleTab } from 'components/ui/ArticleTab/ArticleTab';
import { Button } from 'components/ui/Button/Button';
import { Icon } from 'components/ui/Icon/Icon';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './PreviousLessons.styles';

interface Lesson {
  id: string | null;
  title: string | null;
  completed: boolean;
  subgoals: Array<{
    id: string;
    name: string;
    completed?: boolean;
  }>;
}

interface WeekGroup {
  weekNumber: number;
  lessons: Lesson[];
  isExpanded: boolean;
}

export const PreviousLessons: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.lessons' });
  const navigate = useNavigate();
  const location = useLocation();
  const { allOtherLessonsGoals, loading: goalsLoading, refetch } = useCarePlanGoals();
  const [articlesMap, setArticlesMap] = useState<Record<string, HealthwiseArticleApiResponse>>({});
  const [isLoadingArticles, setIsLoadingArticles] = useState(false);

  // Check for refetch parameter and trigger refetch if present
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('refetch') === 'true') {
      refetch();
      // Remove the refetch parameter from the URL to prevent refetching on every render
      navigate(paths.previousLessons, { replace: true });
    }
  }, [location.search, navigate, refetch]);

  // Fetch articles for all subgoals
  useEffect(() => {
    async function fetchArticles() {
      if (!allOtherLessonsGoals) return;

      setIsLoadingArticles(true);
      try {
        const articleIds = allOtherLessonsGoals
          .flatMap((goal) => goal.subgoals?.map((subgoal) => subgoal?.name?.split(',')[0]) || [])
          .filter((id) => id !== 'custom' && id !== undefined && id !== null) as string[];

        const uniqueArticleIds = Array.from(new Set(articleIds));
        const articles = await HealthwiseApiService.getArticlesByIds(uniqueArticleIds);

        const newArticlesMap: Record<string, HealthwiseArticleApiResponse> = {};
        articles?.forEach((article) => {
          if (article?.data.id) {
            newArticlesMap[article.data.id] = article;
          }
        });

        setArticlesMap(newArticlesMap);
      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        setIsLoadingArticles(false);
      }
    }

    fetchArticles();
  }, [allOtherLessonsGoals]);

  // Group lessons by week
  const weekGroups = useMemo(() => {
    if (!allOtherLessonsGoals || allOtherLessonsGoals.length === 0) {
      return [] as WeekGroup[];
    }

    // Group by week number (extracted from goal name or other property)
    const groupedByWeek: Record<number, Lesson[]> = allOtherLessonsGoals.reduce(
      (acc: Record<number, Lesson[]>, goal) => {
        // Extract week number from goal name or other property
        const weekMatch = goal.name?.match(/Week (\d+)/i);
        const weekNumber = weekMatch ? parseInt(weekMatch[1], 10) : 0;

        if (!acc[weekNumber]) {
          acc[weekNumber] = [];
        }

        // Add the goal to the appropriate week
        acc[weekNumber].push({
          id: goal.id || '',
          title: goal.name || '',
          completed: !!goal.is_completed_for_date,
          subgoals:
            goal.subgoals?.map((subgoal) => ({
              id: subgoal.id || '',
              name: subgoal.name || '',
              completed: !!subgoal.is_completed_for_date,
            })) || [],
        });

        return acc;
      },
      {},
    );

    // Convert to array format for rendering
    return Object.entries(groupedByWeek)
      .map(([weekNum, lessons]) => ({
        weekNumber: parseInt(weekNum, 10),
        lessons,
        isExpanded: parseInt(weekNum, 10) === Math.max(...Object.keys(groupedByWeek).map(Number)), // Expand the latest week
      }))
      .sort((a, b) => b.weekNumber - a.weekNumber); // Sort by week number descending
  }, [allOtherLessonsGoals]);

  const [expandedWeeks, setExpandedWeeks] = useState<Record<number, boolean>>({});

  // Initialize expanded state based on weekGroups
  useEffect(() => {
    if (weekGroups.length > 0) {
      const initialExpandedState = weekGroups.reduce((acc, group) => {
        acc[group.weekNumber] = group.isExpanded;
        return acc;
      }, {} as Record<number, boolean>);

      setExpandedWeeks(initialExpandedState);
    }
  }, [weekGroups]);

  const toggleWeekExpansion = (weekNumber: number) => {
    setExpandedWeeks((prev) => ({
      ...prev,
      [weekNumber]: !prev[weekNumber],
    }));
  };

  const collapseAll = () => {
    const allCollapsed = Object.keys(expandedWeeks).reduce((acc, weekNum) => {
      acc[Number(weekNum)] = false;
      return acc;
    }, {} as Record<number, boolean>);

    setExpandedWeeks(allCollapsed);
  };

  const isAnyWeekExpanded = () => {
    return Object.values(expandedWeeks).some((isExpanded) => isExpanded);
  };

  const getCustomGoalData = (subgoal: { name: string }) => {
    try {
      const customGoalParts = subgoal.name.split(/,(.+)/);
      return JSON.parse(customGoalParts[1] || '{}');
    } catch (error) {
      console.error('Error parsing custom goal data:', error);
      return {};
    }
  };

  if (goalsLoading || isLoadingArticles) {
    return <LoadingPage />;
  }

  // If no data is available, show a message
  if (weekGroups.length === 0) {
    return (
      <Styled.PageContainer>
        <Styled.HeaderContainer>
          <Header onBackClick={() => navigate(-1)} variant="back" title={t('previousLessons')} />
        </Styled.HeaderContainer>
        <Styled.Content>
          <Styled.EmptyState>
            <Typography variant="Body1">No previous lessons available</Typography>
          </Styled.EmptyState>
        </Styled.Content>
        <Styled.ButtonContainer isCollapseVisible={false}>
          <Button onClick={() => navigate(paths.homePage)}>{t('goToHome')}</Button>
        </Styled.ButtonContainer>
      </Styled.PageContainer>
    );
  }

  return (
    <Styled.PageContainer>
      <Styled.HeaderContainer>
        <Header onBackClick={() => navigate(paths.homePage)} variant="back" title={t('previousLessons')} />
      </Styled.HeaderContainer>
      <Styled.Content>
        {weekGroups.map((weekGroup) => (
          <React.Fragment key={weekGroup.weekNumber}>
            <Styled.WeekHeader onClick={() => toggleWeekExpansion(weekGroup.weekNumber)}>
              <Styled.WeekTitle>
                <Typography weight={500} size={20} lineHeight={28} color="blue.700">
                  Week {weekGroup.weekNumber}
                </Typography>
              </Styled.WeekTitle>
              <Icon element={expandedWeeks[weekGroup.weekNumber] ? ChevronDown : ChevronUp} size={24} aria-hidden />
            </Styled.WeekHeader>

            {expandedWeeks[weekGroup.weekNumber] && (
              <Styled.LessonsItems>
                {weekGroup.lessons.map((lesson, idx) => (
                  <React.Fragment key={lesson.id}>
                    {lesson.subgoals && lesson.subgoals.length > 0 ? (
                      lesson.subgoals.map((subgoal, subIdx) => {
                        const articleId = subgoal.name.split(',')[0];
                        const isCustom = articleId === 'custom';
                        const article = isCustom ? null : articlesMap[articleId];
                        const customData = isCustom ? getCustomGoalData(subgoal) : null;

                        if (!isCustom && !article) {
                          return <LoadingPage key={`${lesson.id}-${subgoal.id}`} />;
                        }

                        return (
                          <ArticleTab
                            key={`${lesson.id}-${subgoal.id}`}
                            label={`${t('lesson')} ${subIdx + 1}`}
                            title={isCustom ? customData?.title : article?.data.title?.consumer}
                            hidePlayIcon={isCustom ? customData?.type !== 'video' : article?.data.type !== 'video'}
                            isCompleted={subgoal.completed || false}
                            onClick={() => {
                              navigate(`/lesson/${lesson.id}/${subgoal.id}/${articleId}?from=previous-lessons`);
                            }}
                          />
                        );
                      })
                    ) : (
                      <ArticleTab
                        key={lesson.id}
                        label={`${t('lesson')} ${idx + 1}`}
                        title={lesson.title}
                        hidePlayIcon={false}
                        isCompleted={lesson.completed}
                        onClick={() => {
                          // No navigation if no subgoals
                        }}
                      />
                    )}
                  </React.Fragment>
                ))}
              </Styled.LessonsItems>
            )}
          </React.Fragment>
        ))}
      </Styled.Content>

      <Styled.ButtonContainer isCollapseVisible={isAnyWeekExpanded()}>
        <Button onClick={() => navigate(paths.homePage)}>{t('goToHome')}</Button>
      </Styled.ButtonContainer>

      {isAnyWeekExpanded() && (
        <Styled.CollapseAllButton onClick={collapseAll}>
          <Typography color="blue.700" weight={600}>
            {t('collapseAll')}
          </Typography>
        </Styled.CollapseAllButton>
      )}
    </Styled.PageContainer>
  );
};
