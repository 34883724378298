import { useMutation, useQuery } from '@apollo/client';
import { GET_FORMANSWERGROUP_BY_NAME, UPDATE_FORMANSWER_GROUP } from 'api/notifications/notifications.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { isEmpty } from 'lodash';
import { useState } from 'react';
import generateHTMLFromJSON from 'utils/generateHTMLFromJson';
import { ApptReminderDay, DaysOfWeek, MotivUserData } from 'utils/types';

interface ReminderPreference {
  dayOf: boolean;
  threeDaysBefore: boolean;
}

interface MotivUserDataWithPreferences extends MotivUserData {
  emailReminderPreference?: ReminderPreference;
  textReminderPreference?: ReminderPreference;
  emailReminders?: boolean;
  textReminders?: boolean;
  [key: string]: unknown;
}

const getPreference = (data: MotivUserDataWithPreferences, key: string): ReminderPreference => {
  return data[key] as ReminderPreference;
};

interface NotificationPreferencesHook {
  parsedJSON: MotivUserDataWithPreferences | null;
  userDataLoading: boolean;
  updateLoading: boolean;
  currentUpdating: string;
  handleApptReminderChange: (type: 'email' | 'text', reminderDay: ApptReminderDay) => Promise<void>;
  handleEngagementDayChange: (day: DaysOfWeek) => Promise<void>;
  getReminderPreference: (type: 'email' | 'text', reminderDay: string) => boolean;
  hasIntakeHistory: boolean;
}

export const useNotificationPreferences = (): NotificationPreferencesHook => {
  const [currentUpdating, setCurrentUpdating] = useState('');
  const { data, loading: initialLoading } = useQuery(GET_CURRENT_USER_DATA, { fetchPolicy: 'network-only' });

  const { data: motiv_user_form, loading: formLoading } = useQuery(GET_FORMANSWERGROUP_BY_NAME, {
    variables: {
      userId: data?.currentUser?.id,
      name: 'Motiv user data',
    },
    skip: isEmpty(data?.currentUser?.id),
    fetchPolicy: 'network-only',
  });

  const userDataLoading = initialLoading || formLoading;
  const [updateFormAnswer, { loading: updateLoading }] = useMutation(UPDATE_FORMANSWER_GROUP);

  const motivUserDataForm = motiv_user_form?.formAnswerGroups?.at(0);
  const jsonFormAnswer = motivUserDataForm?.form_answers.find((form) => form.label === 'json_data') || null;
  const prettyJsonFormAnswer =
    motivUserDataForm?.form_answers.find((form) => form.label === 'pretty_json_data') || null;

  const parsedJSON: MotivUserDataWithPreferences = jsonFormAnswer
    ? JSON.parse(jsonFormAnswer?.answer?.match(/<p[^>]*>(.*?)<\/p>/)?.[1] ?? '{}')
    : null;

  const hasIntakeHistory = parsedJSON?.outreach?.some(
    (event) => event.name.includes('Intake Scheduled') || event.name.includes("Intake Didn't Occur"),
  );

  const updateFormAnswerGroup = async (updatedJSON: MotivUserDataWithPreferences): Promise<void> => {
    try {
      const prettyJson = generateHTMLFromJSON(updatedJSON);
      await updateFormAnswer({
        variables: {
          input: {
            id: motivUserDataForm?.id,
            form_answers: [
              {
                answer: `<p style="display:none;">${JSON.stringify(updatedJSON)}</p>`,
                custom_module_id: jsonFormAnswer?.custom_module?.id,
                user_id: data?.currentUser?.id,
              },
              {
                answer: prettyJson,
                custom_module_id: prettyJsonFormAnswer?.custom_module?.id,
                user_id: data?.currentUser?.id,
              },
            ],
          },
        },
        refetchQueries: [GET_FORMANSWERGROUP_BY_NAME],
        fetchPolicy: 'network-only',
      });
    } catch (error) {
      console.error('Failed update user form..', (error as Error).stack);
    }
  };

  const handleApptReminderChange = async (type: 'email' | 'text', reminderDay: ApptReminderDay): Promise<void> => {
    if (!parsedJSON) return;

    setCurrentUpdating(type + reminderDay);
    const preferenceKey = `${type}ReminderPreference`;
    const oldPreferenceKey = `${type}Reminders`;

    if (reminderDay === ApptReminderDay.DayOf) {
      if (!isEmpty(parsedJSON[preferenceKey])) {
        const pref = getPreference(parsedJSON, preferenceKey);
        pref.dayOf = !pref.dayOf;
      } else {
        parsedJSON[preferenceKey] = {
          dayOf: !(parsedJSON[oldPreferenceKey] ?? true),
          threeDaysBefore: parsedJSON[oldPreferenceKey] ?? true,
        };
        parsedJSON[oldPreferenceKey] = undefined;
      }
    } else if (reminderDay === ApptReminderDay.ThreeDaysBefore) {
      if (!isEmpty(parsedJSON[preferenceKey])) {
        const pref = getPreference(parsedJSON, preferenceKey);
        pref.threeDaysBefore = !pref.threeDaysBefore;
      } else {
        parsedJSON[preferenceKey] = {
          dayOf: parsedJSON[oldPreferenceKey] ?? true,
          threeDaysBefore: !(parsedJSON[oldPreferenceKey] ?? true),
        };
        parsedJSON[oldPreferenceKey] = undefined;
      }
    }

    await updateFormAnswerGroup(parsedJSON);
  };

  const handleEngagementDayChange = async (day: DaysOfWeek): Promise<void> => {
    if (!parsedJSON) return;

    setCurrentUpdating(day);
    if (parsedJSON.reminderDays.includes(day)) {
      parsedJSON.reminderDays = parsedJSON.reminderDays.filter((d) => d !== day);
    } else {
      parsedJSON.reminderDays.push(day);
    }

    await updateFormAnswerGroup(parsedJSON);
  };

  const getReminderPreference = (type: 'email' | 'text', reminderDay: string): boolean => {
    if (!parsedJSON) return true;
    const preference = parsedJSON[`${type}ReminderPreference`];
    if (!preference) return parsedJSON[`${type}Reminders`] ?? true;
    return preference[reminderDay as keyof ReminderPreference];
  };

  return {
    parsedJSON,
    userDataLoading,
    updateLoading,
    currentUpdating,
    handleApptReminderChange,
    handleEngagementDayChange,
    getReminderPreference,
    hasIntakeHistory,
  };
};
