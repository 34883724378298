import { useMutation, useQuery } from '@apollo/client';
import { EntryOrderKeys } from '__generated__/graphql';
import { CREATE_GOAL_HISTORY, DELETE_GOAL_HISTORY } from 'api/goal/goal.mutation';
import { GET_GOAL } from 'api/goal/goal.query';
import { CREATE_NOTE, DELETE_NOTE, UPDATE_NOTE } from 'api/notes/notes.mutation';
import { GET_NOTE, GET_NOTES } from 'api/notes/notes.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { format, isToday } from 'date-fns';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DATE_FORMAT, parseHealthieDate } from 'utils/helpers';
import { getCompletionPercentage } from 'utils/helpers/goals';

type Return = {
  note: Note | null;
  loading: boolean;
  updateNoteEntry: (note: Note) => Promise<void>;
  deleteNoteEntry: (note: Note) => Promise<void>;
  markGoalAsCompleted: () => Promise<void>;
  markGoalAsIncomplete: () => Promise<void>;
  isGoalCompleted: boolean;
};

export type Note = {
  id: string;
  content: string;
  createdAt: string;
  updatedAt: string;
};

export const PERSONAL_NOTES_CATEGORY = 'personal_notes';

export const useNote = (): Return => {
  const { noteId, goalId } = useParams();

  const { data: currentUserData, loading: userDataLoading } = useQuery(GET_CURRENT_USER_DATA);
  const userId = useMemo(() => currentUserData?.currentUser?.id, [currentUserData]);
  const [todaysNote, setTodaysNote] = useState<Note | null>(null);
  const [selectedNote, setSelectedNote] = useState<Note | null>(null);
  const [createNote, { loading: createNoteLoading }] = useMutation(CREATE_NOTE);
  const [updateNote] = useMutation(UPDATE_NOTE);
  const [deleteNote] = useMutation(DELETE_NOTE);
  const [createGoalHistory] = useMutation(CREATE_GOAL_HISTORY);
  const [deleteGoalHistory] = useMutation(DELETE_GOAL_HISTORY);
  const [currentNoteContent, setCurrentNoteContent] = useState<string | null>(null);
  const today = format(Date.now(), DATE_FORMAT);

  const goalDate = useMemo(() => {
    if (selectedNote) {
      return format(parseHealthieDate(selectedNote.createdAt), DATE_FORMAT);
    }
    return format(Date.now(), DATE_FORMAT);
  }, [selectedNote]);

  const {
    data: goalData,
    loading: goalLoading,
    refetch: refetchGoal,
  } = useQuery(GET_GOAL, {
    variables: { id: goalId, date: goalDate },
    fetchPolicy: 'network-only',
    skip: isEmpty(goalId),
  });

  const isGoalCompleted = useMemo(() => {
    return getCompletionPercentage(goalData?.goal) === 100;
  }, [goalData]);

  const createTodaysNote = async () => {
    await createNote({
      variables: {
        content: '',
        category: PERSONAL_NOTES_CATEGORY,
      },
      onCompleted: (data) => {
        if (data.createEntry?.entry) {
          setTodaysNote({
            id: data.createEntry.entry.id,
            content: data.createEntry.entry.content ?? '',
            createdAt: data.createEntry.entry.created_at,
            updatedAt: data.createEntry.entry.updated_at,
          });
        }
      },
    });
  };

  const { loading: todaysNoteLoading } = useQuery(GET_NOTES, {
    variables: {
      user_id: userId,
      start_range: today,
      end_range: today,
      category: PERSONAL_NOTES_CATEGORY,
      order_by: EntryOrderKeys.CreatedAtDesc,
    },
    fetchPolicy: 'network-only',
    skip: !userId || !isEmpty(noteId),
    onCompleted: async (data) => {
      if (data.entries) {
        const filteredEntries = data.entries.filter((entry) => entry.category === PERSONAL_NOTES_CATEGORY);
        const todaysEntry = filteredEntries.at(0);
        if (todaysEntry) {
          setTodaysNote({
            id: todaysEntry.id,
            content: todaysEntry.content ?? '',
            createdAt: todaysEntry.created_at,
            updatedAt: todaysEntry.updated_at,
          });
          return;
        }
      }
      if (isEmpty(noteId)) {
        await createTodaysNote();
      }
    },
  });

  const { loading: selectedNoteLoading } = useQuery(GET_NOTE, {
    variables: {
      id: noteId,
    },
    skip: isEmpty(noteId),
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (data.entry) {
        setSelectedNote({
          id: data.entry.id,
          content: data.entry.content ?? '',
          createdAt: data.entry.created_at,
          updatedAt: data.entry.updated_at,
        });
      }
    },
  });

  const markGoalAsCompleted = useCallback(async () => {
    if (goalData?.goal?.id) {
      if (!goalData.goal.is_completed_for_date) {
        try {
          await createGoalHistory({
            variables: {
              user_id: currentUserData?.currentUser?.id,
              goal_id: goalData?.goal?.id,
              completed_on: goalDate,
              mark_parent_complete: true,
            },
            onCompleted: () => {
              refetchGoal();
            },
          });
        } catch (error) {
          // do nothing
        }
      }
    }
  }, [currentUserData, goalData, goalDate, createGoalHistory, refetchGoal]);

  const markGoalAsIncomplete = useCallback(async () => {
    if (goalData?.goal?.id) {
      if (goalData.goal.is_completed_for_date) {
        try {
          await deleteGoalHistory({
            variables: {
              id: goalData.goal.id,
              completed_on: goalDate,
            },
            onCompleted: () => {
              refetchGoal();
            },
          });
        } catch (error) {
          // do nothing
        }
      }
    }
  }, [goalData, goalDate, deleteGoalHistory, refetchGoal]);

  const debouncedUpdateNote = useMemo(
    () =>
      debounce(async (note: Note) => {
        await updateNote({
          variables: {
            id: note.id,
            content: note.content,
          },
          fetchPolicy: 'no-cache',
        });
      }, 125),
    [updateNote],
  );

  const updateNoteEntry = useCallback(
    async (note: Note) => {
      debouncedUpdateNote.cancel();
      setCurrentNoteContent(note.content);
      await debouncedUpdateNote(note);
    },
    [debouncedUpdateNote],
  );

  useEffect(() => {
    if (currentNoteContent != null && currentNoteContent.trim().length) {
      markGoalAsCompleted();
    } else if (currentNoteContent != null) {
      markGoalAsIncomplete();
    }
  }, [currentNoteContent, markGoalAsCompleted, markGoalAsIncomplete]);

  const deleteNoteEntry = async (note: Note) => {
    await deleteNote({
      variables: {
        id: note.id,
      },
      onCompleted: () => {
        if (isToday(parseHealthieDate(note.createdAt))) {
          markGoalAsIncomplete();
        }
      },
    });
  };

  const loading = userDataLoading || todaysNoteLoading || selectedNoteLoading || createNoteLoading || goalLoading;

  const note = useMemo(() => {
    if (isEmpty(noteId)) {
      return todaysNote;
    }
    return selectedNote;
  }, [selectedNote, todaysNote, noteId]);

  return {
    note,
    loading,
    updateNoteEntry,
    deleteNoteEntry,
    markGoalAsCompleted,
    markGoalAsIncomplete,
    isGoalCompleted,
  };
};
