import React from 'react';
import { useNavigate } from 'react-router-dom';
import { paths } from 'router/paths';

import LeftLabelCheckbox from 'components/form/Checkbox/LeftLabelCheckbox/LeftLabelCheckbox';
import { BaseLayout } from 'components/layout/BaseLayout/BaseLayout';
import { Button } from 'components/ui/Button/Button';

import { OnboardingReminderPreferences } from '../constants';
import { useNotificationPreferences } from '../hooks/useNotificationPreferences';
import { ButtonContainer, Container, Note, PreferenceContainer } from './NotificationPreferences.styles';

export const NotificationPreferences: React.FC = () => {
  const navigate = useNavigate();
  const { userDataLoading, updateLoading, currentUpdating, handleApptReminderChange, getReminderPreference } =
    useNotificationPreferences();

  return (
    <BaseLayout
      headerProps={{
        title: 'Set Your Appointment Reminder Preferences',
      }}
      footer={null}
    >
      {OnboardingReminderPreferences.map((section) => (
        <div key={section.type}>
          <Container>{section.heading}</Container>
          {section.options.map((option) => (
            <PreferenceContainer key={`${section.type}${option.type}`}>
              <LeftLabelCheckbox
                label={option.display}
                checked={getReminderPreference(section.type, option.type)}
                onChange={() => handleApptReminderChange(section.type, option.type)}
                loading={userDataLoading || (updateLoading && currentUpdating === section.type + option.type)}
                labelProps={{
                  style: {
                    color: '#4D4D4D',
                    paddingLeft: '1.25rem',
                  },
                }}
              />
            </PreferenceContainer>
          ))}
        </div>
      ))}
      <Note>You can always update these preferences in Settings (Notifications)</Note>
      <ButtonContainer>
        <Button variant="primary" onClick={() => navigate(paths.homePage)}>
          Go to Home
        </Button>
      </ButtonContainer>
    </BaseLayout>
  );
};
