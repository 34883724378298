import { useQuery } from '@apollo/client';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { useCurrentToken } from 'hooks/useCurrentToken';
import { useGetProviders } from 'hooks/useGetProviders';
import { useOrganization } from 'hooks/useOrganization';
import parsePhoneNumberFromString from 'libphonenumber-js';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { onEnterKey } from 'utils/helpers';
import { Mixpanel } from 'utils/mixpanel';

import { StyledButton } from 'components/layout/BaseLayout/BaseLayout.styles';
import { Typography } from 'components/ui/Typography/Typography';

import * as Styled from './GetHelp.styles';
import { GetHelpModal } from './GetHelpModal/GetHelpModal';

const DEFAULT_COACH_PHONE_NUMBER = '1-855-542-1232';

export const GetHelp: React.FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'pages.settings' });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const shouldModalBeOpened = searchParams.get('modal');
  const { data: currentUserData, loading: userDataLoading } = useQuery(GET_CURRENT_USER_DATA);
  const userId = currentUserData?.currentUser?.id;
  const { providers, providersLoading } = useGetProviders({ userId });
  const phoneNumber = useMemo(() => {
    const phoneNumber = providers?.find(
      (provider) => provider?.qualifications?.toLowerCase() === 'coach',
    )?.phone_number;
    if (phoneNumber) {
      const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber, {
        defaultCountry: 'US',
      });
      if (parsedPhoneNumber) {
        const nationalNumber = parsedPhoneNumber.nationalNumber;
        return nationalNumber.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
    }
    return null;
  }, [providers]);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);
  const { getToken } = useCurrentToken();
  const { organizationName } = useOrganization();

  useEffect(() => {
    if (shouldModalBeOpened === 'true') {
      setIsHelpModalOpen(true);
    }
  }, [shouldModalBeOpened]);

  useEffect(() => {
    Mixpanel.track_pageview({ page: 'Settings - Get Help' });
  }, []);

  return (
    <Styled.Container>
      <Styled.HeaderContainer>
        <StyledButton variant="back" onClick={() => navigate(-1)} aria-label={t('goBack')} />
        <Typography.Div variant="Helper1">{t('getHelp')}</Typography.Div>
        <div></div>
      </Styled.HeaderContainer>
      {!userDataLoading && !providersLoading && (
        <Styled.Content>
          <Styled.HelpContent>
            <Typography variant="SubHeadline1">
              {phoneNumber ? t('getHelpPage.callCoach') : t('getHelpPage.callMotiv')}
            </Typography>
            <Typography variant="SubHeadline1" color="blue.600">
              <Styled.RessetedAnchor href={'tel:' + (phoneNumber ? phoneNumber : DEFAULT_COACH_PHONE_NUMBER)}>
                {phoneNumber ? phoneNumber : DEFAULT_COACH_PHONE_NUMBER}
              </Styled.RessetedAnchor>
            </Typography>
            <Typography variant="Helper1">
              {t('getHelpPage.ifYouAreExperiencing')}{' '}
              <Typography
                size={14}
                color="blue.600"
                onClick={() => setIsHelpModalOpen(true)}
                tabIndex={0}
                onKeyDown={(e) => onEnterKey(e, () => setIsHelpModalOpen(true))}
                style={{ cursor: 'pointer' }}
              >
                {t('getHelpPage.signsOrSymptoms')}{' '}
              </Typography>
              {t('getHelpPage.contact911')}
            </Typography>
            <Typography variant="Helper1" color="black.600">
              {t('getHelpPage.coachAvailability')}
            </Typography>
            {organizationName === 'endeavor health' && (
              <Typography variant="Helper1">{t('getHelpPage.urgentIssue')} </Typography>
            )}
          </Styled.HelpContent>
          {getToken() && (
            <Styled.MoreHelpContent>
              <Typography variant="SubHeadline1"> {t('getHelpPage.moreHelp')}</Typography>
              <Typography
                color="blue.600"
                weight={600}
                onClick={() => navigate(`${paths.settings.main}/${paths.settings.faq}`)}
                onKeyDown={(e) => onEnterKey(e, () => navigate(`${paths.settings.main}/${paths.settings.faq}`))}
                tabIndex={0}
              >
                {t('getHelpPage.frequentlyAskedQuestions')}
              </Typography>
              {/* <Typography>
              {t('getHelpPage.orVisit')}{' '}
              <Typography weight={600} color="blue.600">
                <Styled.RessetedAnchor href="https://www.withmotivhealth.com" target="_blank">
                  {t('getHelpPage.motivUrl')}
                </Styled.RessetedAnchor>
              </Typography>
            </Typography> */}
            </Styled.MoreHelpContent>
          )}
        </Styled.Content>
      )}
      <GetHelpModal isOpen={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)}></GetHelpModal>
    </Styled.Container>
  );
};
