import { Organization } from '__generated__/graphql';
import axios from 'axios';
import { env } from 'index';

export const setProgramDate = async (organization: Organization): Promise<void> => {
  const org = organization?.name?.toLowerCase().trim() === 'lee health' ? 'lee' : 'endeavor';
  await axios.post(
    `${env.REACT_APP_AWS_LAMBDA_URL}/${org}/program-start-date`,
    {},
    {
      headers: {
        'healthie-token': localStorage.getItem('token'),
      },
    },
  );
};
