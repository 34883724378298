import styled from 'styled-components';

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.color.other.linen};
  height: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
`;

export const LessonsItems = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${(props) => props.theme.color.black[50]};
`;

export const WeekHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid ${(props) => props.theme.color.black[50]};
  cursor: pointer;
`;

export const WeekTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const ButtonContainer = styled.div<{ isCollapseVisible: boolean }>`
  position: sticky;
  bottom: ${(props) => (props.isCollapseVisible ? '60px' : '0')};
  padding: 16px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.color.other.linen};
  border-top: ${(props) => (!props.isCollapseVisible ? `1px solid ${props.theme.color.black[50]}` : 'none')};
`;

export const CollapseAllButton = styled.div`
  position: sticky;
  bottom: 0;
  padding: 16px 20px;
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: ${(props) => props.theme.color.other.linen};
  cursor: pointer;
  border-top: 1px solid ${(props) => props.theme.color.black[50]};
`;

export const ContentHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  margin: 0 20px;
`;

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;
  text-align: center;
`;
