import styled from 'styled-components';

import { Typography } from 'components/ui/Typography/Typography';

export const Container = styled(Typography.Div)`
  font-size: 16px;
  font-weight: 500;
  color: ${({ theme }) => theme.color.black[600]};
  padding: 20px 20px 10px;
`;

export const PreferenceContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.color.black[50]};
  padding: 15px 20px;
`;

export const ButtonContainer = styled.div`
  padding: 20px;
  margin-top: auto;
`;

export const Note = styled(Typography.P)`
  color: ${({ theme }) => theme.color.black[400]};
  font-style: italic;
  text-align: center;
  padding: 20px;
  font-size: 14px;
`;
