import { ApptReminderDay, DaysOfWeek } from 'utils/types';

const baseReminderPreferences = {
  email: {
    type: 'email' as const,
    options: [
      {
        type: ApptReminderDay.ThreeDaysBefore,
        display: '3 days before appt',
      },
      {
        type: ApptReminderDay.DayOf,
        display: 'Same day of appt',
      },
    ],
  },
  text: {
    type: 'text' as const,
    options: [
      {
        type: ApptReminderDay.ThreeDaysBefore,
        display: '3 days before appt',
      },
      {
        type: ApptReminderDay.DayOf,
        display: 'Same day of appt',
      },
    ],
  },
};

export const ApptReminderPreferences = [
  { ...baseReminderPreferences.email, heading: 'Appointment EMAIL Reminders sent:' },
  { ...baseReminderPreferences.text, heading: 'Appointment TEXT Reminders sent:' },
];

export const OnboardingReminderPreferences = [
  { ...baseReminderPreferences.email, heading: 'EMAIL Reminders (with Zoom link):' },
  { ...baseReminderPreferences.text, heading: 'TEXT Reminders (with Zoom link):' },
];

export const EngagementReminderDays = [
  { name: DaysOfWeek.Monday },
  { name: DaysOfWeek.Wednesday },
  { name: DaysOfWeek.Friday },
];
