import { FitbitAuthProvider } from 'context/FitbitAuth/FitbitAuthProvider';
import { BloodPressureManual } from 'pages/BloodPressureManual/BloodPressureManual';
import { CompletePersonalGoals } from 'pages/CompletePersonalGoals/CompletePersonalGoals';
import { EmailSent } from 'pages/ForgotPassword/EmailSent/EmailSent';
import { ForgotPassword } from 'pages/ForgotPassword/ForgotPassword';
import { NewPassword } from 'pages/ForgotPassword/NewPassword/NewPassword';
import { ResetError } from 'pages/ForgotPassword/ResetError/ResetError';
import { ResetSuccess } from 'pages/ForgotPassword/ResetSuccess/ResetSuccess';
import { RecordBloodPressureSync } from 'pages/RecordBloodPressureSync/RecordBloodPressureSync';
import { RecordYourMedications } from 'pages/RecordYourMedications/RecordYourMedications';
import { RecordYourMedicationsCompleted } from 'pages/RecordYourMedications/RecordYourMedicationsCompleted';
import { RecordYourWeight } from 'pages/RecordYourWeight/RecordYourWeight';
import { RecordYourWeightCompleted } from 'pages/RecordYourWeight/RecordYourWeightCompleted';
import { Appointments } from 'pages/appointments/Appointments';
import { Chat } from 'pages/chat/Chat';
import { ExercisesList } from 'pages/exercisesList/ExercisesList';
import FitbitSetup from 'pages/fitbitSetup/FitbitSetup';
import { FitbitSync } from 'pages/fitbitSync/FitbitSync';
import { Home } from 'pages/home/Home';
import { KeepMoving } from 'pages/keepMoving/KeepMoving';
import { Lesson } from 'pages/lessons/Lesson/Lesson';
import { CompleteYourLessons } from 'pages/lessons/completeYourLessons/CompleteYourLessons';
import { PreviousLessons } from 'pages/lessons/previousLessons/PreviousLessons';
import { Login } from 'pages/logIn/LogIn';
import { Logout } from 'pages/logout/Logout';
import { AllNotes } from 'pages/notes/AllNotes/AllNotes';
import { Note } from 'pages/notes/Note';
import { ConsentStep } from 'pages/onboarding/ConsentStep/ConsentStep';
import { ConsentsAccepted } from 'pages/onboarding/ConsentsAccepted/ConsentsAccepted';
import { MailingAddress } from 'pages/onboarding/MailingAddress/MailingAddress';
import { MeetYourCoach } from 'pages/onboarding/MeetYourCoach/MeetYourCoach';
import { ScheduleFirstAppointment } from 'pages/onboarding/ScheduleFirstAppointment/ScheduleFirstAppointment';
import { SetupPassword } from 'pages/onboarding/SetupPassword/SetupPassword';
import { CarePlan } from 'pages/progress/CarePlan/CarePlan';
import PersonalGoalsProgress from 'pages/progress/PersonalGoalsProgress/PersonalGoalsProgress';
import { Progress } from 'pages/progress/Progress';
import { Activity } from 'pages/progress/activity/Activity';
import ActivityReadings from 'pages/progress/activity/readings/ActivityReadings';
import { BloodPressure } from 'pages/progress/bloodPressure/BloodPressure';
import BloodPressureReading from 'pages/progress/bloodPressure/readings/BloodPressureReadings';
import { MedicationsReadings } from 'pages/progress/medications/MedicationsReadings';
import { WeeklyPlan } from 'pages/progress/weeklyReport/WeeklyReport';
import { Questionnaire } from 'pages/questionnaires/Questionnaire/Questionnaire';
import { QuestionnaireComplete } from 'pages/questionnaires/QuestionnaireComplete/QuestionnaireComplete';
import { Questionnaires } from 'pages/questionnaires/Questionnaires';
import { RescheduleAppointmentSlot } from 'pages/scheduleAppointment/RescheduleAppointmentSlot';
import { ScheduleAppointmentSlot } from 'pages/scheduleAppointment/ScheduleAppointmentSlot';
import { SelectAppointmentType } from 'pages/scheduleAppointment/SelectAppointmentType';
import { ActionCompleted } from 'pages/scheduleAppointment/components/ScheduleActions/ActionCompleted';
import { Settings } from 'pages/settings/Settings';
import { AccountSettings } from 'pages/settings/components/Accountsettings/AccountSettings';
import { Devices } from 'pages/settings/components/Devices/Devices';
import { Faq } from 'pages/settings/components/Faq/Faq';
import { GetHelp } from 'pages/settings/components/GetHelp/GetHelp';
import { MyConsents } from 'pages/settings/components/MyConsents/MyConsents';
import { NotificationPreferences } from 'pages/settings/components/Notifications/NotificationPreferences/NotificationPreferences';
import Notifications from 'pages/settings/components/Notifications/Notifications';
import { PersonalInfo } from 'pages/settings/components/PersonalInfo/PersonalInfo';
import { Appointment } from 'pages/singleAppointment/Appointment';
import { Welcome } from 'pages/welcome/Welcome';
import React from 'react';
import { Navigate, createBrowserRouter } from 'react-router-dom';

import { generateDisabledRoute, generateProtectedRoute } from './middleware';
import { paths } from './paths';

export const router = createBrowserRouter([
  {
    path: paths.progress,
    element: generateProtectedRoute(<Progress />),
  },
  {
    path: paths.scheduleAppointment,
    element: generateProtectedRoute(<SelectAppointmentType />),
  },
  {
    path: paths.scheduleAppointmentWithSpecialist,
    element: generateProtectedRoute(<ScheduleAppointmentSlot />),
  },
  {
    path: paths.appointments,
    element: generateProtectedRoute(<Appointments />),
  },
  {
    path: paths.singleAppointment,
    element: generateProtectedRoute(<Appointment />),
  },
  {
    path: paths.rescheduleAppointment,
    element: generateProtectedRoute(<RescheduleAppointmentSlot />),
  },
  {
    path: paths.appointmentActionConfirmation,
    element: generateProtectedRoute(<ActionCompleted />),
  },
  {
    path: paths.appointmentActionConfirmationMeetYourCoach,
    element: generateProtectedRoute(<MeetYourCoach />),
  },
  {
    path: paths.homePage,
    element: generateProtectedRoute(<Home />),
  },
  {
    path: paths.logInPage,
    element: generateDisabledRoute(<Login />),
  },
  {
    path: paths.logoutPage,
    element: <Logout />,
  },
  {
    path: paths.welcomePage,
    element: generateDisabledRoute(<Welcome />),
  },
  {
    path: paths.mailingAddress,
    element: generateProtectedRoute(<MailingAddress />),
  },
  {
    path: paths.settings.main,
    children: [
      {
        index: true,
        element: generateProtectedRoute(<Settings />),
      },
      {
        path: paths.settings.personalInfo,
        element: generateProtectedRoute(<PersonalInfo />),
      },
      {
        path: paths.settings.accountSettings,
        element: generateProtectedRoute(<AccountSettings />),
      },
      {
        path: paths.settings.consents,
        element: generateProtectedRoute(<MyConsents />),
      },
      {
        path: paths.settings.faq,
        element: <Faq />,
      },
      {
        path: paths.settings.getHelp,
        element: <GetHelp />,
      },
      {
        path: paths.settings.devices,
        element: generateProtectedRoute(<Devices />),
      },
      {
        path: paths.settings.notifications,
        element: generateProtectedRoute(<Notifications />),
      },
    ],
  },
  {
    path: paths.setupFirsAppointment,
    element: generateProtectedRoute(<ScheduleFirstAppointment />),
  },
  {
    path: paths.notificationPreferences,
    element: generateProtectedRoute(<NotificationPreferences />),
  },
  {
    path: paths.setupPassword,
    element: generateDisabledRoute(<SetupPassword />),
  },
  {
    path: paths.chat,
    element: generateProtectedRoute(<Chat />),
  },
  // View user manual (triggered from second time enter)
  {
    path: paths.bloodPressureManual,
    element: generateProtectedRoute(<BloodPressureManual />),
  },
  // First time enter
  {
    path: paths.bloodPressureManualFirstTimeSlides,
    element: generateProtectedRoute(<BloodPressureManual firstTimeEnter />),
  },
  // Second time enter
  {
    path: paths.recordYourBloodPressureSync,
    element: generateProtectedRoute(<RecordBloodPressureSync />),
  },
  {
    path: paths.consents,
    element: generateProtectedRoute(<ConsentStep />),
  },
  {
    path: paths.consentsAccepted,
    element: generateProtectedRoute(<ConsentsAccepted />),
  },
  {
    path: paths.completeYourlessons,
    element: generateProtectedRoute(<CompleteYourLessons />),
  },
  {
    path: paths.previousLessons,
    element: generateProtectedRoute(<PreviousLessons />),
  },
  {
    path: paths.completeYourExerciseProgram,
    element: generateProtectedRoute(<ExercisesList />),
  },
  {
    path: paths.completeYourPersonalGoals,
    element: generateProtectedRoute(<CompletePersonalGoals />),
  },
  {
    path: paths.lesson,
    element: generateProtectedRoute(<Lesson />),
  },
  {
    path: paths.recordYourWeight,
    element: generateProtectedRoute(<RecordYourWeight />),
  },
  {
    path: paths.recordYourWeightCompleted,
    element: generateProtectedRoute(<RecordYourWeightCompleted />),
  },
  {
    path: paths.recordYourMedications,
    element: generateProtectedRoute(<RecordYourMedications />),
  },
  {
    path: paths.recordYourMedicationsCompleted,
    element: generateProtectedRoute(<RecordYourMedicationsCompleted />),
  },
  {
    path: paths.questionnaires,
    element: generateProtectedRoute(<Questionnaires />),
  },
  {
    path: paths.questionnaireById,
    element: generateProtectedRoute(<Questionnaire />),
  },
  {
    path: paths.questionnaireComplete,
    element: generateProtectedRoute(<QuestionnaireComplete />),
  },
  /*********************************************************/
  // Reset password
  {
    path: paths.forgotPassword,
    element: <ForgotPassword />,
  },
  {
    path: paths.emailSent,
    element: <EmailSent />,
  },
  {
    path: paths.chooseNewPassword,
    element: <NewPassword />,
  },
  {
    path: paths.resetSuccess,
    element: <ResetSuccess />,
  },
  {
    path: paths.resetError,
    element: <ResetError />,
  },
  /*********************************************************/
  // Progress
  {
    path: paths.activity,
    element: generateProtectedRoute(<Activity />),
  },
  {
    path: paths.keepMoving,
    element: generateProtectedRoute(<KeepMoving />),
  },
  {
    element: <FitbitAuthProvider />,
    children: [
      {
        path: paths.fitbitSync,
        element: generateProtectedRoute(<FitbitSync />),
      },
      {
        path: paths.fitbitSetup,
        element: generateProtectedRoute(<FitbitSetup />),
      },
    ],
  },
  {
    path: paths.activityReadings,
    element: generateProtectedRoute(<ActivityReadings />),
  },
  {
    path: paths.progress,
    element: generateProtectedRoute(<Progress />),
  },
  {
    path: paths.weeklyReport,
    element: generateProtectedRoute(<WeeklyPlan />),
  },
  {
    path: paths.carePlan,
    element: generateProtectedRoute(<CarePlan />),
  },
  {
    path: paths.bloodPressure,
    element: generateProtectedRoute(<BloodPressure />),
  },
  {
    path: paths.bloodPressureReadings,
    element: generateProtectedRoute(<BloodPressureReading />),
  },
  {
    path: paths.weight,
    element: generateProtectedRoute(<>Progress - Weight</>),
  },
  {
    path: paths.medications,
    element: generateProtectedRoute(<MedicationsReadings />),
  },
  {
    path: paths.personalGoals,
    element: generateProtectedRoute(<PersonalGoalsProgress />),
  },
  {
    path: paths.todaysNote,
    element: generateProtectedRoute(<Note />),
  },
  {
    path: paths.note,
    element: generateProtectedRoute(<Note />),
  },
  {
    path: paths.allNotes,
    element: generateProtectedRoute(<AllNotes />),
  },
  /*********************************************************/

  // Redirect to home page when path does not exist (needs to be last item in array)
  {
    path: '*',
    element: <Navigate to={paths.homePage} replace />,
  },
]);
