import { useQuery } from '@apollo/client';
import '@healthie/chat/dist/styles/index.css';
import { Chat as HealthieChat, HealthieProvider } from '@healthie/sdk';
import { Conversation } from '__generated__/graphql';
import { GET_USER_CONVERSATIONS } from 'api/chat/chat.query';
import { GET_CURRENT_USER_DATA } from 'api/user/user.query';
import { ReactComponent as Send } from 'assets/vectors/send.svg';
import { formatInTimeZone } from 'date-fns-tz';
import { useOrganization } from 'hooks/useOrganization';
import { client } from 'index';
import capitalize from 'lodash/capitalize';
import inRange from 'lodash/inRange';
import { GetHelpModal } from 'pages/settings/components/GetHelp/GetHelpModal/GetHelpModal';
import React, { MutableRefObject, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import * as ReactDOMServer from 'react-dom/server';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { paths } from 'router/paths';
import { useTheme } from 'styled-components';
import { capitalizeWords, parseHealthieDate } from 'utils/helpers';
import { Mixpanel } from 'utils/mixpanel';

import { ChatLayout } from 'components/layout/ChatLayout/ChatLayout';
import { Alert } from 'components/ui/Alert/Alert';
import { Icon } from 'components/ui/Icon/Icon';
import LoadingPage from 'components/ui/LoadingPage/LoadingPage';
import { Tabs } from 'components/ui/Tabs/Tabs';
import { Typography } from 'components/ui/Typography/Typography';

import {
  AlertWrapper,
  Bold,
  ChatWrapper,
  MessageWrapper,
  SendIconWrapper,
  StyledButton,
  TabsWrapper,
} from './Chat.styles';
import { EmptyPlaceholder } from './components/EmptyPlaceholder/EmptyPlaceholder';

const WORKING_HOURS_START = 8;
const WORKING_HOURS_END = 16;

export const Chat: React.FC = () => {
  const theme = useTheme();
  const { t } = useTranslation('translation', { keyPrefix: 'pages.chat' });
  const navigate = useNavigate();
  const [isHelpModalOpen, setIsHelpModalOpen] = useState(false);

  const [searchParams] = useSearchParams();
  const tabParam = searchParams.get('tab');

  const location = useLocation();

  const [layoutHeight, setLayoutHeight] = useState<number>(0);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [navigationHeight, setNavigationHeight] = useState<number>(theme.nav.heightNumber);

  const [chatHeight, setChatHeight] = useState<number>(0);
  const [currentTab, setCurrentTab] = useState<number | undefined>(undefined);
  const [isKeyboardVisible, setIsKeyboardVisible] = useState<boolean>(false);

  const layoutRef = useRef() as MutableRefObject<HTMLDivElement>;
  const headerRef = useRef() as MutableRefObject<HTMLDivElement>;
  const tabsRef = useRef() as MutableRefObject<HTMLDivElement>;
  const navigationRef = useRef() as MutableRefObject<HTMLDivElement>;
  const { organizationName } = useOrganization();

  // queries
  const {
    data: currentUserData,
    loading: currentUserLoading,
    error: currentUserError,
  } = useQuery(GET_CURRENT_USER_DATA);
  const {
    data: conversationsData,
    loading: conversationsLoading,
    error: conversationsError,
  } = useQuery(GET_USER_CONVERSATIONS, {
    fetchPolicy: 'network-only',
  });

  const userId = currentUserData?.currentUser?.id;
  const allConversations = conversationsData?.conversations;

  const coachConversation = allConversations?.find(
    (c: Conversation) => c.owner?.qualifications?.toLowerCase() === 'coach',
  );
  const therapistConversation = allConversations?.find(
    (c: Conversation) => c.owner?.qualifications?.toLowerCase() === 'therapist',
  );

  const filteredConversations = useMemo(
    () => [coachConversation, therapistConversation].filter((conversation) => conversation),
    [coachConversation, therapistConversation],
  );

  // Effects
  // It's necessary to reset Apollo store because without calling this method, subscription skips some messages
  useEffect(() => {
    client.resetStore();
  }, [currentTab]);

  // Mixpanel for chat is triggered in Navigation component before refreshing unless
  useEffect(() => {
    const isFromNav = location.state?.fromNav;
    if (!isFromNav) {
      Mixpanel.track_pageview({ page: 'Chat' });
    }
  }, [location.state]);

  useEffect(() => {
    const int = setInterval(() => {
      if (!layoutHeight && !headerHeight && navigationHeight) {
        setLayoutHeight(layoutRef.current?.clientHeight || 0);
        setHeaderHeight((headerRef.current?.clientHeight || 0) + 32);
        setNavigationHeight(navigationRef.current?.clientHeight || 0);
        clearInterval(int);
      }
    }, 100);
  });

  const calculateChatHeight = () => {
    const tabsHeight = tabsRef.current?.clientHeight || 0;
    let height = layoutHeight - (headerHeight + tabsHeight);

    if (!isKeyboardVisible) {
      height = height - navigationHeight;
    }

    // Add a check for currentTab
    if (currentTab === undefined) {
      // Reduce height by an estimated amount to prevent covering the message box
      height -= 60; // Adjust this value as needed
    }

    setChatHeight(height);
  };

  const updateEmptyChatPlaceholder = () => {
    const changeEmptyPlaceholderInterval = setInterval(() => {
      if (filteredConversations?.length && filteredConversations[currentTab as number]?.last_message_content == null) {
        // it's necessary to overwrite placeholder message
        const emptyPlaceholder: HTMLElement | null = document.querySelector('div.empty-placeholder');

        if (emptyPlaceholder) {
          const patientName = capitalizeWords(filteredConversations[currentTab as number]?.invitees[0]?.first_name);
          const providerName = capitalizeWords(filteredConversations[currentTab as number]?.owner?.first_name);
          const providerRole = filteredConversations[currentTab as number]?.owner?.qualifications;
          emptyPlaceholder.innerHTML = ReactDOMServer.renderToString(
            <EmptyPlaceholder
              patientName={capitalize(patientName)}
              providerName={capitalize(providerName)}
              providerRole={capitalize(providerRole)}
            />,
          );
          emptyPlaceholder.style.setProperty('display', 'block', 'important');
          clearInterval(changeEmptyPlaceholderInterval);
        }
      }
    }, 100);
  };

  useEffect(calculateChatHeight, [
    filteredConversations,
    headerHeight,
    isKeyboardVisible,
    layoutHeight,
    navigationHeight,
    currentTab,
  ]);

  // DOM manipulation
  useLayoutEffect(() => {
    const manipulateDomNodesInterval = setInterval(() => {
      // it's necessary to change the button content
      const sendButton = document.querySelector('div.chat-input-toolbox button.chat-input-button');
      const input = document.querySelector('div.editor-container div.ProseMirror');

      if (isMobile && input) {
        input.addEventListener('focus', () => {
          setTimeout(() => {
            setIsKeyboardVisible(true);
          }, 50);
        });
        input.addEventListener('blur', (e) => {
          e.preventDefault();

          // I need to call setTimeout because it doesn't work without it
          setTimeout(() => {
            setIsKeyboardVisible(false);
          }, 50);
        });
      }

      if (sendButton) {
        sendButton.ariaLabel = t('sendMessage');
        sendButton.innerHTML = ReactDOMServer.renderToString(
          <SendIconWrapper>
            <Icon element={Send} color={'other.white'} size={24} />
          </SendIconWrapper>,
        );
      }

      if (input && sendButton) {
        clearInterval(manipulateDomNodesInterval);
      }
    }, 10);
  }, [filteredConversations, currentTab, t]);

  useLayoutEffect(updateEmptyChatPlaceholder, [filteredConversations, currentTab, isKeyboardVisible]);

  // helpers
  const shouldDisplayOutOfOfficeAlert = (): boolean => {
    const currentDate = new Date();

    // Display "Out Of Office" alert on weekends
    const dayOfWeek = formatInTimeZone(currentDate, 'America/New_York', 'EEEE');
    if (['saturday,', 'sunday'].includes(dayOfWeek.toLowerCase())) {
      return true;
    }

    // Calculate whether an "Out Of Office" alert should be displayed
    // calculation is based on America/New_York time zone
    const currentHour = formatInTimeZone(currentDate, 'America/New_York', 'H');

    // WORKING_HOURS_END + 1 because inRange not include end value
    return !inRange(parseInt(currentHour), WORKING_HOURS_START, WORKING_HOURS_END + 1);
  };

  const onTabChange = (index: number) => {
    setCurrentTab(index);
    navigate(`${paths.chat}?tab=${index === 1 ? 'therapist' : 'coach'}`, { replace: true });
  };

  // JSX
  let content;

  if (currentUserLoading || conversationsLoading) {
    content = <LoadingPage />;
  }

  if (currentUserError || conversationsError) {
    content = <MessageWrapper>{t('somethingWentWrong')}</MessageWrapper>;
  }

  if (allConversations?.length && !filteredConversations.length) {
    content = <MessageWrapper>{t('contactSupport')}</MessageWrapper>;
  }

  if (userId && filteredConversations.length && currentTab !== undefined) {
    const tabs = [
      { label: t('coach'), ariaLabel: t('coachTabAriaLabel') },
      { label: t('therapist'), ariaLabel: t('therapistTabAriaLabel') },
    ];

    const conversation = filteredConversations[currentTab];
    const shouldDisplayAlert = shouldDisplayOutOfOfficeAlert();
    content = (
      <HealthieProvider userId={userId}>
        {filteredConversations.length > 1 && (
          <TabsWrapper ref={tabsRef}>
            <Tabs tabs={tabs} selectedTab={currentTab} onTabChange={onTabChange} />
          </TabsWrapper>
        )}

        <ChatWrapper className="chat-container" style={{ height: '100%' }}>
          {conversation && <HealthieChat conversationId={conversation.id} />}
          {shouldDisplayAlert && (
            <AlertWrapper>
              <Alert
                content={
                  <div className="react-markdown">
                    <Bold>{t('outOfOfficeMessage.ifYouAreExp')} </Bold>
                    <StyledButton onClick={() => setIsHelpModalOpen(true)}>
                      <Bold style={{ cursor: 'pointer' }}>{t('outOfOfficeMessage.signOrSymptoms')}</Bold>
                    </StyledButton>
                    <Bold>
                      {' '}
                      {t('outOfOfficeMessage.emergency')}{' '}
                      {organizationName === 'endeavor health' ? t('outOfOfficeMessage.endeavorSupport') : ''}
                    </Bold>
                    <Typography.P variant={'Helper1'} style={{ paddingTop: '1rem' }}>
                      {' '}
                      {t('outOfOfficeMessage.message')}
                    </Typography.P>
                  </div>
                }
              />
            </AlertWrapper>
          )}
        </ChatWrapper>
      </HealthieProvider>
    );
  }

  useEffect(() => {
    if (conversationsData && currentTab === undefined) {
      let tabIndex = 0;
      let mostRecentTimestamp = -1;
      let hasUnviewedConversation = false;

      for (let index = 0; index < filteredConversations.length; index++) {
        const conversation = filteredConversations[index];
        const membership = conversation?.current_user_conversation_membership;

        if (!membership.viewed) {
          hasUnviewedConversation = true;
          const updatedAt = membership?.convo?.updated_at;

          let currentTimestamp = 0;
          if (updatedAt) {
            const parsedDate = parseHealthieDate(updatedAt);
            currentTimestamp = parsedDate.getTime();
          }

          if (currentTimestamp > mostRecentTimestamp) {
            mostRecentTimestamp = currentTimestamp;
            tabIndex = index;
          }
        }
      }

      if (hasUnviewedConversation) {
        setCurrentTab(tabIndex);
      } else if (tabParam) {
        setCurrentTab(tabParam === 'therapist' ? 1 : 0);
      } else {
        setCurrentTab(0);
      }
    }
  }, [conversationsData, filteredConversations, tabParam, currentTab]);

  return (
    <ChatLayout
      layoutRef={layoutRef}
      headerRef={headerRef}
      navigationRef={navigationRef}
      hideNavigation={isKeyboardVisible}
    >
      <div style={{ height: `${chatHeight}px` }}>{content}</div>
      <GetHelpModal isOpen={isHelpModalOpen} onClose={() => setIsHelpModalOpen(false)}></GetHelpModal>
    </ChatLayout>
  );
};
